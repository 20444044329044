import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/young-smiling-woman-sitting-in-cafe.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const OralSurgeryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_oral_1 = data.service_oral_1?.childImageSharp?.fluid
  const service_oral_2 = data.service_oral_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Oral Surgery - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer many types of oral surgeries to help you achieve a healthy mouth and the perfect smile. Browse the page below to learn more. It's easy to schedule" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Oral Surgery - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer many types of oral surgeries to help you achieve a healthy mouth and the perfect smile. Browse the page below to learn more. It's easy to schedule  " />
      <meta property="og:url" content="https://thedentistsatgc.com/services/oral-surgery/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1333" />
      <meta property="og:image:height" content="2000" />
      <meta property="og:image:alt" content="young-smiling-woman-sitting-in-cafe" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Oral Surgery - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer many types of oral surgeries to help you achieve a healthy mouth and the perfect smile. Browse the page below to learn more. It's easy to schedule" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              ORAL & MAXILLOFACIAL SURGERY MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Oral Surgery
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of oral surgeries to help you achieve a healthy mouth and the perfect smile. Browse the page below to learn more. It’s easy to schedule your appointment with us.</p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">ORAL SURGEON SERVICES</p>
              <h2 className ="section-2-header">Oral Surgery Can Stop Your Pain and Discomfort!</h2>
              <p>Oral surgery can help you relieve pain and restore proper functioning of the mouth. Sometimes our oral health isn’t sustainable without surgery. When any part of your mouth, especially your teeth or gums, starts to hurt it can be distracting and sometimes unbearable. Mouth surgery can bring you the relief that you need to get back to school or work. </p>
              <p>If you’re nervous about getting surgery on your teeth or gums, that is normal. You can feel confident knowing that Dr. Vogt is a highly trained dental surgeon. He will be with you from start to finish to help you feel comfortable and know that you’re making the right decision. We understand that your oral health is important to you and that is why we offer our patients oral surgery procedures at our practice.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improved Appearance</h3>
                <p>A healthy appearance is paramount to proper development and acceptance in social settings. With oral surgery you can improve the appearance of your teeth and gums making you more attractive.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improved Speech</h3>
                <p>Oral surgery can fix certain types of speech impediments leading to better speech and improved communication.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                <p>Imagine being able to to talk, laugh, smile, and let people see your teeth with out feeling embarrassed. That is sure to make you feel good about yourself!</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improves Overall Dental Health</h3>
                <p>Eliminating problems in your mouth will let you keep your teeth and gums healthier for longer. You will feel good about putting in the extra effort to maintain your smile.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Enjoy Eating Again</h3>
                <p>When you have a full set of teeth and a mouth that is free of problems, eating and drinking become much easier.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improve Your Social Life</h3>
                <p>Fixing an unsightly or painful issue with your mouth can bring you more than just relief and health. When you smile confidently at others they will interact with you in a more positive manner.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_oral_1} alt="Blond Lady Smiling Beautifully" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_oral_2} alt="A Happy Young Man With a Hat Standing in Front of a Wall" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                STOP THE PAIN!
                </p>
                <h3 className="text-light">
                Don't miss out on another good day because you haven't taken care of your mouth!
                </h3>
                <p className="hero-text text-light">
                Dr. Vogt can help you to not have pain and discomfort in your mouth. Whether the solution is surgery or something else, don’t miss your chance to get it fixed right away. Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">DENTAL SURGERY DETAILS</p>
              <h2 className ="section-3-header">What is Oral Surgery?</h2>
             <p>Oral surgery is the treatment of oral health issues by performing surgery on the tissues of your mouth, including your teeth and gums. Oral surgery requires local anesthesia and sometimes sedation. For more information on<Link to="/services/sedation-dentistry"> sedation dentistry click here.</Link></p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is Oral Surgery Right For You?</h3>
                <p>You think you need oral surgery, maybe you’re nervous and you’re unsure if it’s the right move for you. We understand and we’re here to help you come to the right decision. The pain and discomfort caused by an abnormality in your teeth or gums can keep you from living a normal life. If you are suffering from disease or an injury that has affected your mouth and you would like to get it fixed you may be a candidate for surgery. Dr. Vogt will consult you every step of the way. Not every problem needs surgery to fix, we will make sure you know all of your options before committing to this one. </p>
                <p>After surgery, we will provide you with the information that you need to recover quickly and get back to enjoying the good things in life again like food and smiling. </p>
                <p>Imagine your self without the pain and discomfort you have now. We can help you get there and Dr. Vogt will be with you every step of the way. Just give us a call to schedule a consultation.</p>
                <h3>Stop suffering from pain and discomfort.</h3>
                <p>Imagine your self without the pain and discomfort you have now. We can help you get there and Dr. Vogt will be with you every step of the way. Just give us a call to schedule a consultation.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Oral Surgeries Offered To Our Patients</h3>
                <p>Dr. Vogt is trained in several procedures. He can help you with:</p>
                <ul>
                  <li>extractions</li>
                  <li>bone grafts</li>
                  <li>gum grafts</li>
                  <li>dental implants</li>
                  <li>wisdom tooth removal</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Oral Surgery Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default OralSurgeryPage

export const query = graphql`
  query OralSurgeryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_oral_1: file(name: { eq: "oral_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_oral_2: file(name: { eq: "oral_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
